// Otp api
export const checkUserRegisteredURL = `/api/otps/isRegistered`;
export const sendLoginOtpURL = `/api/otps/send`;
export const verifyOtpURL = `/api/otps/verify`;

// signup user api
export const accountURL = `/api/configurations/ACCOUNT_TYPE`;
export const genderURL = `/api/configurations/GENDER`;
export const languageURL = `/api/configurations/LANGUAGES`;
export const physicalStatusURL = `/api/configurations/PHYSICAL_STATUS`;
export const addSignupURL = `/api/candidates/signup`;
export const candidateInfoURL = `/api/candidates`;
export const subAccTypeURL = `/api/configurations/SUB_ACCOUNT_TYPES`;
export const highestQualificationURL = `/api/configurations/HIGHEST_QUALIFICATION`;
export const educationalAreaURL = `/api/configurations/GRAD_EDUCATION_STREAM`;
export const engSpecializationURL = `/api/configurations/ENGG_SPECIALIZATION`;
export const dipSpecializationURL = `/api/configurations/DIPLOMA_EDUCATION_STREAM`;
export const itiSpecializationURL = `/api/configurations/ITI_EDUCATION_STREAM`;
export const medSpecializationURL = `/api/configurations/MEDICAL_SPECIALIZATION`;
export const districtURL = `/api/configurations/INDIA_DISTRICTS`;
export const instituteTypeURL = `/api/configurations/INSTITUTE_TYPE`;
export const educationTypeURL = `/api/configurations/EDUCATION_TYPE`;
export const eduStudyCatURL = `/api/configurations/EDU_STUDY_CAT`;
export const candidateIdentityURL = `/api/candidates/verification/identity`;
export const educationVerifyURL = `/api/candidates/verification/education`;
export const employmentVerifyURL = `/api/candidates/verification/employment`;
export const socialVerifyURL = `/api/candidates/verification/social`;
export const policeVerifyURL = `/api/candidates/verification/police`;
export const fitnessVerifyURL = `/api/candidates/verification/fitness`;
export const eduStudyCategoryURL = `/api/configurations/EDU_STUDY_CAT`;

//Profile progress
export const profileProgress = `/api/user/incompleteProfile`;
export const latestRole = `/api/user/latestRole`;
export const latestAssociation = `/api/recruiter/latestAssociation`;

// basic page api
export const idtypeURL = `/api/configurations/ID_PROOF_TYPE`;
export const idProofURL = `/api/configurations/VERIFY_ID`;
export const educationProofURL = `/api/configurations/VERIFY_EDUCATION`;
export const employmentProofURL = `/api/configurations/VERIFY_EMPLOYMENT`;

//company profile registration
export const companyURL = `/api/company`;
export const companyBasicURL = `/api/company/basic`;
export const otherInformationURL = `/api/company/otherInformation`;
export const instituteCreateCompanyURL = `/api/institute/createCompany`;
export const companyUpdateOfferValidityURL = `/api/company/offerValidityDays`;

// company profile
export const companyDetailURL = '/api/company';
export const companyGstURL = `/api/company/gst`;
export const compantFollowersCountURL = '/api/company/getFollowerCount';
export const userCompaniesURL = '/api/company/userCompanies';
export const updateCompanyBasicURL = '/api/company/basic';
export const updateCompanyOtherInformationURL = '/api/company/otherInformation';
export const instituteCompanyListURL = '/api/institute/allCompanies';

// employer admin profile
export const employerAdminDetailsURL = '/api/employers';
export const employerAdminBasicDetailsURL = '/api/employers/basic';
export const employerAdminWorkURL = '/api/employers/work';
export const employerAdminEducationURL = '/api/employers/education';
export const adminDetailsURL = '/api/employers';
export const adminBasicDetailsURL = '/api/employers/basic';
export const adminWorkURL = '/api/employers/work';
export const adminEducationURL = '/api/employers/education';
export const adminFollowersCountURL = '/api/employers/getFollowerCount';
export const removeEmployerDoc = '/api/employers/doc';

//employer
export const employerPostInfoURL = `/api/employers/info`;
export const employerInfoURL = '/api/employers';
export const yearsURL = `/api/configurations/YEARS`;
export const employersWorkURL = `/api/employers/work`;
export const employersBasicURL = `/api/employers/basic`;
export const skillSetsURL = `/api/configurations/SKILLS`;
export const entityTypeURL = `/api/configurations/ENTITY_TYPE`;
export const employersEducationURL = `/api/employers/education`;
export const employerTypeURL = `/api/configurations/EMPLOYER_TYPE`;
export const associatedIndustriesURL = `/api/configurations/INDUSTRIES`;
export const coursesAttendedURL = `/api/configurations/COURSES_ATTENDED`;
export const natureOfIndustryURL = `/api/configurations/NATURE_OF_INDUSTRY`;
export const natureOfBusinessURL = `/api/configurations/NATURE_OF_BUSINESS`;
export const businessModelURL = `/api/configurations/BUSINESS_MODEL`;
export const serviceOfferURL = `/api/configurations/BUSINESS_SERVICES`;
export const certificationsURL = `/api/configurations/PROFSNL_CERTIFICATIONS`;
export const educationalQualificationsURL = `/api/configurations/HIGHEST_QUALIFICATION`;
export const createEmployerURL = '/api/employers/signupByOther';

//referred-users
export const referredUsersURL = `/api/referredUsers/`;
export const referredUserListURL = `/api/referredUsers/list`;
export const referredUserSendOtpURL = `/api/referredUsers/sendOtp/`;
export const referredUserVerifyOtpURL = `/api/referredUsers/verifyOtp/`;
export const referredUserWithdrawOtpURL = `/api/referredUsers/withdrawOtp/`;
export const updateReferredUserURL = '/api/referredUsers';

//Image Upload
export const imageUploadURL = `/api/documents`;
export const documentURL = `/api/documents`;
export const jobDocURL = `/api/job/doc`;

//Document
export const candidateDocURL = `/api/candidates/doc`;
export const candidateBGDocURL = `/api/candidates/verification`;

// location page api
export const countryURL = `/api/configurations/COUNTRIES`;
export const stateURL = `/api/configurations/INDIA_STATES`;
export const citySearchURL = '/api/configurations/';
export const residenceURL = `/api/configurations/RESIDENCE_STATUS`;

// personal page api
export const maritalURL = `/api/configurations/MARITAL_STATUS`;
export const religionURL = `/api/configurations/RELIGIONS`;
export const physicalURL = `/api/configurations/PHYSICAL_STATUS`;
export const disabilityURL = `/api/configurations/TYPE_OF_DISABILITY`;

// education page api
export const qualificationURL = `/api/configurations/HIGHEST_QUALIFICATION`;
export const qualification2URL = `/api/configurations/SECOND_HIGHEST_QUALIFICATION`;

// work detail page api
export const workDetailsURL = `/api/candidates/work`;
export const worktypeURL = `/api/configurations/WORK_TYPE_PERFORMED`;
export const locationTypeURL = `/api/configurations/LOCATIONS_TYPE`;
export const timingURL = `/api/configurations/TIMINGS_OF_WORK_DONE`;
export const hiredURL = `/api/configurations/HIRED_TYPE`;
export const salaryModeURL = `/api/configurations/SALARY_MODE`;
export const indianStatesURL = `/api/configurations/INDIA_STATES`;
export const yearURL = `/api/configurations/YEARS`;
export const monthURL = `/api/configurations/MONTHS`;
export const dayURL = `/api/configurations/DAYS`;
export const industryURL = `/api/configurations/INDUSTRIES`;
export const natindusURL = `/api/configurations/NATURE_OF_INDUSTRY`;
export const areaworkURL = `/api/configurations/AREA_OF_WORK`;
export const rolesPerformedURL = `/api/configurations/ROLES_PERFORMED`;
export const skillsURL = `/api/configurations/SKILLS`;
export const countriesWorkedURL = `/api/configurations/COUNTRIES_WORKED`;
export const exyearURL = `/api/configurations/EXPERIENCE_YEARS`;
export const exmonthURL = `/api/configurations/EXPERIENCE_MONTHS`;
export const domainURL = `/api/configurations/DOMAIN`;
export const erpProductsURL = `/api/configurations/ERP_PRODUCTS`;
export const marksRecordURL = `/api/configurations/CANDIDATE_MARKS_RECORD`;
export const instituteCandidateCoursesURL = `/api/configurations/INSTITUTE_CANDIDATE_COURSES`;
export const instituteCandidateGradesURL = `/api/configurations/CANDIDATE_GRADE`;
export const instituteGradeSystenURL = `/api/configurations/INSTITUTE_GRADE_POINT_SYSTEM`;
export const candidateInstituteURL = `/api/candidates/institute`;

// preference page url
export const empTypeURL = `/api/configurations/EMPLOYER_TYPE`;
export const travelRequireURL = `/api/configurations/TRAVEL_REQUIREMENTS`;
export const placeRelocateURL = `/api/configurations/PLACE_RELOCATE`;
export const userPreferenceURL = `/api/candidates/preference`;

// dashboard header api
export const userRolesURL = `/api/user/me`;
export const profileScoreURL = `/api/candidates/getProfileScore`;

// change number api
export const getOtpOldPhoneURL = `/api/user/sendOtpChangeNumber`;
export const verifyChangeOtpURL = `/api/user/verifyOtpChangeNumber`;
export const getOtpNewNumberURL = `/api/user/sendOtpNewNumber`;
export const verifyNewNumberURL = `/api/user/verifyOtpNewNumber`;

// filter jobs search api
export const postingDateURL = `/api/configurations/JOB_POSTING_DATES`;
export const experienceURL = `/api/configurations/EXPERIENCE`;
export const salRangeURL = `/api/configurations/ANNUAL_SALARY_RANGE`;
export const ageRangeURL = `/api/configurations/AGE_RANGE`;
export const jobAllowanceURL = `/api/configurations/JOB_ALLOWANCES`;
export const workDurationURL = `/api/configurations/CONTRACT_DURATION`;
export const timeToFillPostURL = `/api/configurations/TIME_TO_FILL_POST`;
export const distanceKmsURL = `/api/configurations/distanctInKm`;
export const activityJobURL = `/api/configurations/ACTIVITY_LEVEL_JOB_SEARCH`;
export const locationPrefrenceURL = `/api/configurations/LOCATION_TRAVEL_READY`;
export const indiaDistrictsURL = `/api/configurations/INDIA_DISTRICTS`;
export const instituteCandidateDefaultConsentURL = `/api/configurations/DEFAULT_INSTITUTE_CANDIDATE_CONSENT`;
export const instituteCandidateConsentURL = `/api/institute/candidate/consent`;

// search api
export const searchApiArr = [
  `/api/job/basic/search?`,
  `/api/job/advance/search?`,
  `/api/job/advance/search/filter`,
];

// dashboard page api
export const analyticURL = `/api/employerAction/getAnalyticCount`;
export const recruitCountURL = `/api/candidates/getFollowedRecruiterCount`;
export const companyCountURL = `/api/candidates/getFollowedCompanyCount`;
export const savedJobCountURL = `/api/saveJob/getSavedJobCount`;

// dashboard sidebar api
export const activelySearchingURL = `/api/candidates/activelySearchingJob`;
export const getAvailablityImmediatelyURL = `/api/candidates/availability`;
export const getActivelySearchingURL = `/api/candidates/activelySearchingJob`;

// set user pin
export const setDashboardPinURL = `/api/user/setPin`;
export const changeDashboardPinURL = `/api/user/changePin`;
export const verifyOtpAndCreatePinURL = `/api/otps/verifyOtpAndCreatePin`;
export const verifyPinURL = `/api/otps/verify/pin`;
export const isUserPinExistURL = `/api/otps/isPinExist`;
export const isUserEmailPinExistURL = `/api/otps/isEmailPinExist`;

// candidate api
export const applicationCountURL = `/api/application/count/candidate`;

// candidate dashboard settings api
export const candidateSetVisibilityURL = `/api/candidates/setVisibility`;
export const candidateStatusURL = `/api/candidates/status`;
export const deleteReasonURL = `/api/configurations/REASON_DELETION`;

// profile sections
export const profileSectionURL = `/api/candidates/profileSectionFlag`;

// candidate employer/admin details
export const getEmployerInfoURL = `/api/employers`;
export const employerContactDetailURL = `/api/employers/contactDetail`;

export const employerWorkInfoURL = `/api/employers/work`;
export const employerJobURL = `/api/job/employer`;
export const franchiseeJobURL = `/api/franchisee`;
export const fetchAgencyCompanyName = `/api/franchisee/companyNames`;
export const fetchAgencyJobsList = `/api/franchisee/jobs`;
export const fetchAgencyAssociatedJobsList = `/api/franchisee/associatedJobs`;
export const fetchAssociatedCompanyNames = `/api/franchiseeCompanyAssociation/companyNames`;
export const instituteAssociatedCompanyNames = `/api/instituteCompanyAssociation/associatedCompanyNames`;
export const createCandidateApplication = `/api/application`;

export const recruiterBasicURL = `/api/recruiter/basic`;
export const recruiterContactDetailURL = `/api/recruiter/contactDetail`;
export const recruiterEducationURL = `/api/recruiter/education`;
export const recruiterFollowerCountURL = `/api/recruiter/getFollowerCount`;
export const recruiterWorkURL = `/api/recruiter/work`;
export const getRecruiterInfoURL = `/api/admin`;
export const adminBasicinfoURL = `/api/admin/basic`;
export const adminContactDetailURL = `/api/admin/contactDetail`;
export const adminEducationInfoURL = `/api/admin/education`;
export const adminFollowerCountURL = `/api/admin/getFollowerCount`;
export const adminWorkInfoURL = `/api/admin/work`;

// api for employer/company details
export const companyInfoURL = `/api/company`;
export const checkIfUserFollowingURL = `/api/candidates/isFollowing`;
export const getCompanyFollowersURL = `/api/company/getFollowerCount`;
export const getCompaniesByTierURL = `/api/company/companies`;
export const deleteCompanyDocsUrl = `/api/company/delete`;

// api for candidate profile details
export const basicUserDetailURL = `/api/candidates/basic`;
export const userLocationDetailURL = `/api/candidates/location`;
export const userContactDetailURL = `/api/candidates/contactDetail`;
export const userEducationDetailURL = `/api/candidates/education`;
export const userEducationRecordURL = `/api/candidates/education/qualifications/marksRecord`;
export const userEducationVerifyURLNew = `/api/candidates/education/qualifications/marksRecord/verify`;
export const userEducationVerifyURL = `/api/candidates/education/qualifications/verify/`;
export const userAllEducationVerifyURL = `/api/candidates/education/verify`;
export const userPersonalDetailURL = `/api/candidates/personal`;

// api for candidate view applied job
export const viewAppliedJobURL = `/api/application`;
export const jobListingDataURL = `/api/job/listing`;
export const getJobDetailURL = `/api/job/detail?jobIdentifier`;
export const setJobPostView = `/api/saveJob/setJobPostView`;
export const checkJobNotificationOnURL = `/api/candidates/isNotificationOn`;

// api for candidate status activities
export const getFollowedRecruitersURL = `/api/candidates/getFollowedRecruiters`;
export const getFollowedCompaniesURL = `/api/candidates/getFollowedCompanies`;

// api for candidate employer action
export const employerActionURL = `/api/employerAction`;
export const employerActionMultiURL = `/api/employerAction/invite/multi`;
export const employerActionShortlistURL = `/api/employerAction/shortlist`;

// api for candidate franchisee action
export const franchiseeActionShortlistURL = `/api/employerAction/saveProfile`;

// apply job
export const applyJobURL = `/api/application/create`;
export const applyMultiURL = `/api/application/create/multi`;
// job by admin
export const getjobsRecruiterURL = `/api/job/admin`;
// candidate profile registration
export const candidateBasicInfoURL = `/api/candidates/basic`;
export const candidateLocationURL = `/api/candidates/location`;
export const candidatePersonalURL = `/api/candidates/personal`;
export const candidateEducationURL = `/api/candidates/education`;
export const candidateWorkURL = `/api/candidates/work`;
export const candidateDomainURL = `/api/candidates/workDomain`;

// config
export const companyNameURL = `/api/configurations/COMPANY_NAME`;
export const healthReportURL = `/api/configurations/HEALTH_REPORT_CATEGORY`;
export const pricingPlanURL = `/api/configurations/PRICING_PLAN`;

// save job post
export const saveJobPostURL = `/api/saveJob/saveJobForCandidate`;
// delete job post
export const deleteJobPostURL = `/api/saveJob/deleteJob`;

// employer status
export const employerStatusURL = `/api/employers/status`;
export const employerAssociationStatusURL = `/api/employers/association`;

// get candidate saved jobs
export const getSavedJobs = `/api/saveJob/getCandidateSavedJobs`;

// candidate profile views
export const getCandidateViewURL = `/api/candidates/profileViews`;

/* ****************************************************************************** */
// employer api
export const empSignupURL = `/api/employers/signup`;
export const recruiterSignupURL = `/api/recruiter/signup`;

// refer contacts api
export const referContactApi = `/api/referContacts`;

//Post Job
export const configuraionApi = `/api/configurations/`;
export const postJob = `/api/job/summary`;
export const postJobDetailInformationURL = `/api/job/listing`;
export const recruiterList = `/api/recruiter/activeList`;
export const employerDetail = `/api/employers`;
export const jobVisibilityURL = `/api/configurations/JOBS_VISIBILITY`;
export const ageURL = `/api/configurations/AGE`;
export const jobDeleteReasonsURL = `/api/configurations/JOB_REASON_CLOSURE`;
export const jobDeleteURL = `/api/job/delete`;
export const jobPostList = `/api/job/list`;
export const parentJobListURL = `/api/job/parentList`;
export const jobVisibleURL = `/api/job/publicVisibility`;
export const franchiseeJobVisibility = `/api/job/private`;

// get recruiters list
export const getRecruitersListURL = `/api/recruiter/list`;
export const statusListURL = `/api/configurations/STATUS`;
export const associateRecruiterStatusURL = `/api/recruiter/associationStatus`;

// recruiter status api
export const recruiterStatusURL = `/api/recruiter/status`;

// recruiter info api
export const recruiterInfoURL = `/api/recruiter`;

// api for recruiter doc
export const recruiterDocURL = `/api/recruiter/doc`;
// nearest city
export const nearestCity = `/api/city/nearest`;

// notify
export const turnOffNotifyURL = `/api/candidates/turnOffNotification`;
export const turnOnNotifyURL = `/api/candidates/turnOnNotification`;
export const postJobURL = `/api/job/status`;

// api for job list dropdown
export const getJobListDropdownURL = `/api/job/list`;

// api for invite
export const inviteURL = `/api/invite`;

// Employer Dashboard Application Tab
export const applicationStatusURL = `/api/configurations/APPLICATION_STATUS`;
export const jobListURL = `/api/job/list`;
export const employerApplicationURL = `/api/application/employer`;
export const applicationJobURL = `/api/application/job`;
export const applicationJobStages = `/api/application/all/status`;
export const applicationJobStageStatus = `/api/application/all/stageStatus`;

// Employer Dashboard Analytics Tab
export const companyFollowerCountURL = `/api/company/getFollowerCount`;
export const employerFollowerCountURL = `/api/employers/getFollowerCount`;

export const employerJobPostViewCountURL = `/api/saveJob/getJobPostViewCount`;
export const employerJobCountsURL = `/api/job/count`;

export const jobStatusURL = `/api/configurations/JOBS_STATUS`;
export const recruiterJobListURL = `/api/job/recruiter`;
export const employerDashboardAnalyticsCountURL = `/api/employers/dashboard/analytics`;
export const employerDashboardApplicationStatusURL = `/api/application/all/status`;
export const employerDashboardStageStatusURL = `/api/application/all/stageStatus`;

// employer search
export const employerSearchURL = `/api/candidates/advance/search/filter`;
export const sendSingleCandidateInviteURL = `/api/invite`;
export const sendMultiCandidateInviteURL = `/api/invite/create/multi`;
export const updateApplicationStatusURL = `/api/application/update`;

// applied job list
export const appliedJobListURL = `/api/application/candidate`;

// api for job list
export const getJobListURL = `/api/job/getJobList`;
export const getJobCountURL = `/api/application/count/job`;
export const jobInviteCountURL = `/api/employerAction/invite/count`;

// api for pricing
export const getPricingPlanURL = `/api/plan`;
export const getPricingPlanCompanyIdURL = `/api/plan/activePlan/`;
export const getPricingSheetURL = `/api/plan/candidate`;

// api for categories group
export const categoriesGroupURL = `/api/configurations/CANDIDATE_NOTIFICATIONS_GRPNG`;
export const empCategoriesGroupURL = `/api/configurations/EMPLOYERS_NOTIFICATIONS_GRPNG`;

// api for Notifications
export const notificationsURL = `/api/notification`;

// api for razorpay
export const orderURL = `/api/plan/order`;
export const paymentURL = `/api/plan/payment`;
export const franchiseeProfilePlanURL = `/api/plan/franchiseeProfile/STANDARD`;

// api for candidate dashboard
export const candidateAnalyticURL = `/api/candidates/dashboard/analytics`;

// recruiter dashboard api
export const recruiterAnalyticsURL = `/api/recruiter/dashboard/analytics`;

// employer shortlisted job action
export const employerShortlistURL = `/api/employerAction/shortlist/jobs`;
export const employerInvitedURL = `/api/employerAction/invite/jobs`;
export const jobUpdatesURL = `/api/job/updatedJobs`;
export const invitedCandidatesPerJob = `/api/candidates/invited`;

export const employerInviteURL = `/api/employerAction/invite`;

export const getJobByCompanyURL = `/api/job/company`;

export const getIsShortlistedURL = `/api/employerAction/isShortlisted`;
export const getMatchingJobsURL = `/api/job/matchingJobs`;
export const getMatchingCandidateURL = `/api/job/matchingCandidates`;
export const getIsInvitedURL = `/api/employerAction/isInvited`;

export const jobPostURL = `/api/job`;
export const singleJobPostURL = `/api/job/jobPost`;

export const empOrderHistoryURL = `/api/plan/searchOrders`;
export const loadOrderByIdURL = `/api/plan/order`;

// **************** FRANCHISEE APIS ***************** //
export const verifyDocketURL = `/api/franchisee/isDocketValid`;
export const franchiseeSignupURL = `/api/franchisee/signup`;
export const getFranchiseURL = `/api/franchisee`;
export const franchiseeAnalyticsURL = `/api/franchisee/analytics`;
export const franchiseeTypeURL = `/api/configurations/FRANCHISEE_TYPE`;
export const verifySoCodeURL = `/api/franchisee/isServiceCodeValid`;
export const candidateAssociatedURL = `/api/franchisee/associatedCandidates`;
export const employerAssociatedURL = `/api/franchisee/associatedEmployers`;
export const instituteEmployerAssociatedURL = `/api/instituteCompanyAssociation/associatedEmployers`;
export const paidEmployerURL = `/api/franchisee/paidEmployers`;
export const franchiseeStatusURL = `/api/franchisee/status`;
export const franchiseeOrderURL = `/api/franchisee/orders`;
export const intituteListURL = `/api/configurations/INSTITUTES_LIST`;
export const verifyInstiuteURL = `/api/institute/isInstituteCodeValid`;
export const createCandidateURL = `/api/franchisee/candidate/signup`;
export const isUserExist = `/api/user/isUserExist`;
export const franchiseeOrdersURL = `/api/franchisee/orders`;
export const requestOtpURL = `/api/franchisee/sendUserRegistrationOtp`;
export const verifyNewOtpURL = `/api/otps/verify`;
export const franchiseeCreateCompanyURL = `/api/franchisee/createCompany`;
export const franchiseeCompanyListURL = `/api/franchisee/allCompanies`;
export const franchiseeCompanyNamesURL = `/api/franchisee/companyNames`;
export const franchiseeCompanyDeleteURL = `/api/franchisee/companyNames`;
export const franchiseeEmpOrdersURL = `/api/franchisee/associatedEmployerOrders`;
export const franchiseeAssociatedCompanyNamesURL = `/api/franchiseeCompanyAssociation/companyNames`;
export const franchiseeIdentiferURL = `/api/franchisee/serviceCode`;
export const isSoAtiveURL = `/api/franchisee/isServiceCodeActive`;
export const franchiseeComAssocURL = `/api/franchiseeCompanyAssociation`;
export const shareCandidateApplication = `/api/application`;
export const instituteJobsList = `/api/job/institute`;
export const instituteJobsListassociatedJobs = `/api/job/institute/associatedJobs`;
export const franchiseeCompanyAssociation = `/api/franchiseeCompanyAssociation/franchiseeNames`;
export const jobCriteriaEditURL = `/api/job/jobShareCriteria`;

// ***************** ADMIN APIS ******************* //
export const verifyPasswordURL = `/api/otps/verify/password`;
export const adminDashboardURL = `/api/admin/dashboard`;
export const verifyInstituteDocketURL = `/api/institute/docket/isValid`;
export const instituteSignupURL = `/api/institute/signup`;
export const instituteURL = `/api/institute`;
export const signupCandidateOtherURL = `/api/candidates/signupByOther`;
export const instituteAnalyticsURL = `/api/institute/dashboard/analytics`;
export const instituteAssocCandidateURL = `/api/institute/associatedCandidates`;
export const placementURL = `/api/candidates/placement`;
export const instituteCourseURL = `/api/institute/course`;
export const instituteBatchURL = `/api/institute/batch`;
export const coursePassingYearURL = `/api/institute/batch/passingYears`;
export const coursePassingMonthURL = `/api/institute/batch/passingMonths`;
export const batchNamesURL = `/api/institute/batch/names`;
export const courseNamesURL = `/api/institute/course/names`;
export const coursesByCategoryURL = `/api/institute/course/names/{instituteEducationCategoryId}?instituteEducationCategoryId=`;
export const academicInstURL = `/api/candidates/academic`;
export const instituteRequestOtpURL = `/api/institute/sendUserRegistrationOtp`;
export const isRegisteredURL = `/api/user/isUserRegistered`;
export const adminCompanyListUrl = `/api/company/admin/companies`;
export const adminCandidateListUrl = `/api/admin/candidates`;

// ****************** Aadhaar API ******************* //
export const getCaptchaURL = `/api/candidates/verification/uid/captcha`;
export const uidVerifyURL = `/api/candidates/verification/uid`;
export const newIdProofURL = `/api/configurations/ID_PROOF_TYPE`;

// ****************** Institutes API ******************* //
export const getAllActiveInstituteNameCode = `/api/institute/allActiveInstituteNameCode`;
export const instituteByCodeURL = `/api/institute/instituteCode`;
export const institutebatchcandidates = `/api/institute/batch/candidates`;
export const instituteCandidateExport = `/api/candidateInstituteAssociation/export/candidates`;
export const instituteRecordUpdate = `/api/candidateInstitute/update`;
export const instituteCompanyTiers = `/api/configurations/COMPANY_EMPLOYER_TIRE`;
export const updateCompanyTier = `/api/company/tier`;

// ****************** Agency Agreement API ******************* //
export const getAllAgreements = `/api/franchiseeCandidateAgreement`;
export const approveAgreement = `/api/franchiseeCandidateAgreement/{franchiseeCandidateAgreementId}/approve`;
export const rejectAgreement = `/api/franchiseeCandidateAgreement/batch/{franchiseeCandidateAgreementId}/reject`;

// Company Institute Empanelment Request
export const createCompanyInstituteRequestURL = `/api/instituteCompanyAssociation/createRequest`;
export const updateCompanyInstituteRequest = `/api/instituteCompanyAssociation/updateRequest`;
export const getRequestsForCompany = `/api/instituteCompanyAssociation/requestForCompany`;
export const getRequestsForInsitute = `/api/instituteCompanyAssociation/requestForInstitute`;
export const getRequestsForBlockUnBlock = `/api/instituteCompanyAssociation`;

//Candidate Institute Empanelment Request
export const getCandidateInstituteAssociationRequest = `/api/candidateInstituteAssociation/request`;
export const createCandidateInstituteAssociationRequest = `/api/candidateInstituteAssociation/request`;
export const rejectCandidateInstituteAssociationRequest = `/api/candidateInstituteAssociation/request`;
export const approveCandidateInstituteAssociationRequest = `/api/candidateInstituteAssociation/request`;
export const getCandidateInstituteAssociationCandidatesRequest = `/api/candidateInstituteAssociation/candidates`;
export const getCandidateInstituteConsentStatusURL = `/api/candidateInstituteAssociation/candidate/consent`;
export const getCandidateInstituteBaseURL = `/api/candidateInstituteAssociation/institute`;
export const verifyCandidateUrl = `/api/candidates/background/verify`;

export const sendOTPForAadhaarURL = `/api/candidates/verification/uid`;
export const verifyAadhaarOTPURL = `/api/candidates/verification/uid`;
export const instituteCompaniesURL = `/api/institute/companyNames`;
export const instituteCompanyAssociation = `/api/instituteCompanyAssociation/associatedCompanyNames`;

// ******************* Skilling Group API ******************** //
export const getSkillingUserURL = `/api/skillingUser`;
export const deleteSkillingUserURL = `/api/institute/deleteSkillingUser`;
export const createSkillingUserURL = `/api/skillingUser/createProfile`;
export const sendOTPSkillingUserURL = `/api/skillingUser/sendOTP`;
export const withdrawOTPSkillingUserURL = `/api/institute/withdrawSkillingUserOTP`;

// New code start ====== >>>>> Franchise Institute Empanelment Request
export const getComanyList = `/api/company/employerCompanyNames/`;
export const getFranchiseRequest = `/api/franchiseeCompanyAssociation/franchisee/request`;
export const getFranchiseEmployerRequest = `/api/franchiseeCompanyAssociation/employer/request`;
export const createFranchiseRequest = `/api/franchiseeCompanyAssociation/request`;
export const rejectFranchiseRequest = `/api/franchiseeCompanyAssociation/request/`;
export const approveFranchiseRequest = `/api/franchiseeCompanyAssociation/request/`;
export const getFranchiseDetail = `/api/franchisee/serviceCode/`;
export const createProposals = `/api/franchiseeCompanyContract`;

//Contract List
export const getFranchiseeCompanyContractsList = `/api/franchiseeCompanyContract/getAll`;
export const getFranchiseeCompanyContractAssociation = `/api/franchiseeCompanyAssociation/getAll`;
export const getSelectedContractDetails = `/api/franchiseeCompanyContract/`;
export const changeContractStatus = `/api/franchiseeCompanyContract/`;

export const getCandidatetoFranchiseRequest = `/api/candidateFranchiseeAssociation/franchisee/request`;
export const getCandidatetoFranchiseRequestListing = `/api/candidateFranchiseeAssociation/candidate/request`;
export const getFranchiseList = `/api/franchisee/franchiseeNames/`;
export const createCandidateRequest = `/api/candidateFranchiseeAssociation/request`;
export const rejectCandidateRequest = `/api/candidateFranchiseeAssociation/request/`;
export const approveCandidateRequest = `/api/candidateFranchiseeAssociation/request/`;
export const sendEmailOtpURL = `/api/user/sendOtpToNewEmail`;
export const verifyEmailOtpURL = `/api/user/verifyOtpForNewEmail`;
export const sendMobileOtpURL = `/api/user/sendOtpNewNumber`;
export const verifyMobileOtpURL = `/api/user/verifyOtpNewNumber`;
export const createEmployeragencyassociationURL =
  '/api/franchiseeCompanyAssociation';
export const candidateGetAgency = `/api/candidates/associatedFranchisees`;
export const candidateGetAgencyJobsByID = `/api/franchisee/`;
// **** Bulk file upload **** //
export const uploadBulkFileURL = `/api/import/importCandidate`;
export const getCandidatesRecords = `/api/import/importcandidates`;
export const candidateAgenciesEmpanelledURL = `/api/candidates/associatedFranchisees`;
export const sharedJobAppliationURL = `/api/application/shared/job`;
export const setPlacementURL = `/api/candidates/setPlacement`;
export const apiInstituteSearchURL = `/api/institute/search`;
export const employerInstitutesURL = `/api/instituteCompanyAssociation/associatedInstitutes`;
export const mappedInstitutesURL = `/api/recruiter/institute/association`;
export const candidateSearchPreferencesURL = `/api/candidates/searchPreference`;
export const processListURL = `/api/import/searchImportProcess`;
export const candidateImportLogURL = `/api/import/candidateImportLog`;
export const candidateImportURL = `/api/import/candidates`;
export const searchImportedCandidatesURL = `/api/import/allCandidates`;
export const instituteCompanyAssociationURL = `/api/instituteCompanyAssociation/associatedInstituteNames`;
export const agencyViewedCandidatesURL = `/api/employerAction/viewed/candidates`;
export const instituteGetSpecializationURL = `/api/institute/getSpecializations`;
export const instituteSpecializationURL = `/api/institute/specialization`;
export const instituteEducationCategoryURL = `/api/institute/educationCategory`;
export const intituteJobListURL = `/api/job/institute`;
export const intituteassociatedJobJobListURL = `/api/job/institute/associatedJobsgetInstituteAssociatedJobs`;
export const applicationByJobURL = `/api/application/byJob`;
export const applicationExportURL = `/api/application/export/applications`;
export const searchBatchURL = `/api/institute/batch/search`;
export const instituteAssociatedCompaniesURL = `/api/instituteCompanyAssociation/associatedCompanies`;
export const instituteBatchCandidatesURL = `/api/institute/batch/candidates`;
export const agreementTermsURL = `/api/candidateFranchiseeTerm/create`;
export const createAgreement = `/api/franchiseeCandidateAgreement`;
export const sharedByName = `/api/job/shared/jobNames`;
export const sharedByEmployer = `/api/job/sharedBy/employer`;
export const broadcastNoticeBaseURL = `/api/message/broadcast`;
export const broadcastCompanyNoticeBaseURL = `/api/company/message/broadcast`;
